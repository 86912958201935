import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/raporty-pracy.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    
    zdjecie1: file(relativePath: { eq: "raport-konfiguracja.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "raport-widgety.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "raport-koszt.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "raport-rentownosc.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "raport-kontrahenci.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie6: file(relativePath: { eq: "raport-pracochlonnosc.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie7: file(relativePath: { eq: "raport-czas.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie8: file(relativePath: { eq: "raport-sprzet.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie9: file(relativePath: { eq: "raport-magazyn.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie10: file(relativePath: { eq: "raport-sprzedaz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const raportpracy = ({ data }) => {
  return (
    <Artykul
      title="Automatyczny raport pracy serwisowej. Zarządzaj serwisem w&nbsp;jednym narzędziu."
      articleImage={obrazekArtykulu}
      articleImageAlt="Automatyczny raport pracy serwisowej. Zarządzaj serwisem w jednym narzędziu."
      metaTitle="Automatyczny raport pracy serwisowej. Zarządzaj serwisem w jednym narzędziu."
      metaDescription="Raport pracy serwisowej 📈 Rentowność i koszt zleceń ᛫ Czas poświęcony na zlecenia ᛫  Liczba zleceń ᛫ Magazyn ᛫ Analizuj automatyczne raporty!"
    >
        <Container className="container container--text">
        <p>
        Prowadzenie działalności biznesowej nakierowanej na realizację procesów serwisowych nie 
        ogranicza się tylko do skutecznego planowania pracy. Zakres obowiązków osób zarządzających 
        przedsiębiorstwami tego rodzaju jest o wiele szerszy. Jednym z kluczowych aspektów, którymi 
        powinni się oni zajmować jest <strong>mierzenie efektywności prowadzonych działań i dogłębna 
        analiza przyczyniająca się do wprowadzania korzystnych usprawnień</strong>. W procesie tym 
        znaczenie powinien mieć każdy pojedynczy raport pracy – tak, aby żaden istotny szczegół nie 
        został pominięty.
        </p>
        <p style={{
          paddingBottom: 20,
        }}>
        Zarządzasz firmą serwisową i zastanawiasz się w jaki sposób możesz sprawnie tworzyć niezbędne 
        zestawienia? Obawiasz się nadmiarowej liczby rozwiązań informatycznych? Chcesz działać szybko 
        i&nbsp;nie poświęcać ogromnej ilości czasu na generowanie raportów pracy? Zapoznaj się z&nbsp;naszym
        materiałem i&nbsp;dowiedz się, jak program do obsługi zleceń może Ci w tym pomóc.
        </p>
        <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="cDEwQhAXk58"
          title="Raportowanie pracy w programie serwisowym. Jak usprawni Twoją pracę?"
        />
      </div>

        </Container>
    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Raportowanie i&nbsp;analiza – dlaczego ich połączenie jest istotne?</strong>
      </h2>
      <p>
      Raportowanie i analiza to procesy, które powinny współistnieć i uzupełniać się. Realizowanie ich 
      odrębnie jest dużym błędem i sprawia, że kłopotliwe staje się wyciąganie słusznych wniosków. 
      Dlaczego? <strong>Raportowanie</strong> polega wyłącznie na przejrzystym gromadzeniu istotnych 
      informacji w zestawienia służące procesowi analizy. To w zasadzie zebranie danych i&nbsp;przygotowanie 
      ich do wnioskowania. Krok jest ten niezbędny do efektywnego przeprowadzenia wszystkich działań, 
      ale samodzielnie nie jest wystarczający. Konieczna jest <strong>analiza</strong>, która – korzystając 
      z&nbsp;danych zawartych w raportach – pozwala na wyciągnięcie istotnych informacji i&nbsp;podejmowanie decyzji 
      służących usprawnieniom biznesowym.
      </p>
      </Container>

       <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawnie analizować raporty pracy serwisowej?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;raportować działania w&nbsp;programie do serwisu
            </Link>
          </div>
          </div>
    </section>


      <Container className="container container--text">

       <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Analizuj raporty pracy i&nbsp;usprawnij swój biznes</strong>
      </h2>

      <p>
      Moduł raportowania, w który wyposażony jest program serwisowy charakteryzuje się tym, że jego 
      celem jest maksymalne uproszczenie procesu tworzenia zestawień. Dzięki odpowiednim 
      funkcjonalnościom <strong>raportowanie odbywa się w zasadzie samo</strong>, a Ty, jako osoba 
      odpowiedzialna za proces analiz, możesz przejść do nich szybciej i poświęcić im dużo więcej 
      uwagi. O&nbsp;jakich możliwościach mowa? Sprawdźmy!
      </p>

      </Container>
      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Automatyzacja działań</strong></h3>

     <p>
     Każdy pojedynczy raport pracy w programie do obsługi zleceń tworzy się automatycznie, co 
     sprawia, że gromadzone w rozwiązaniu informacje są grupowane w odpowiedni sposób 
     i umieszczane w przejrzystych wizualnie zestawieniach. Automatyzacja procesu raportowania 
     przyczynia się do ogromnej oszczędności czasu. W związku z&nbsp;tym, że raporty tworzą się same, 
     osoba odpowiedzialna za ich analizę może natychmiast przejść do swojej pracy i nie skupiać 
     się na żmudnym organizowaniu danych w tabelach i wykresach. <strong>Poświęcenie uwagi tylko 
     jednemu zadaniu może przyczynić się do wzrostu efektywności realizowanych czynności i&nbsp;wpłynąć 
     na poziom proponowanych usprawnień.</strong>
     </p>
      </Container>

      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Raport pracy – elastyczny i&nbsp;konfigurowalny</strong></h3>

      <p>
      Czytając o gotowych raportach pracy w&nbsp;programie do zarządzania zleceniami serwisowymi można 
      pomyśleć, że to ograniczenie swobody organizowania informacji. Wcale tak nie jest. Oprócz 
      automatyzacji <strong>moduł raportowania charakteryzuje się także ogromnymi możliwościami 
      konfiguracyjnymi i&nbsp;elastycznością</strong>. Odpowiednie opcje – filtrowanie, sortowanie, 
      grupowanie, ustawienia widoczności kolumn i opcje wykresu – pozwalają na samodzielne 
      podejmowanie decyzji co do prezentowanych danych. Dzięki tej funkcjonalności każdy 
      z raportów pracy może przedstawić dowolne informacje, w&nbsp;zależności od Twoich aktualnych potrzeb.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie1]}
        alts={["Konfiguracja raportu pracy"]}
      />
      </Container>

      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Możliwość eksportu raportów pracy</strong></h3>

      <p>
      Nierzadko zdarza się, że osoby odpowiedzialne za proces analiz potrzebują dokonywać 
      zaawansowanych działań w&nbsp;zewnętrznych arkuszach kalkulacyjnych. Z&nbsp;tego względu 
      istnieje <strong>możliwość pobrania każdego raportu pracy do pliku z&nbsp;rozszerzeniem .csv 
      i .xslx.</strong> Wyeksportowany dokument jest tym samym zestawieniem co wygenerowany 
      automatycznie raport w systemie do obsługi zleceń. Dzięki takiemu rozwiązaniu nie ma 
      konieczności ręcznego przepisywania danych – zyskujesz w ten sposób czas i&nbsp;działasz bezbłędnie. 
      </p>
      </Container>

      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Raporty pracy w&nbsp;formie widgetów</strong></h3>

      <p>
      W każdej działalności biznesowej liczy się czas. Z tego względu warto mieć niezbędne zestawienia 
      zawsze pod ręką. Pozwala to na bieżące monitorowanie istotnych wskaźników i sprawne reagowanie 
      w&nbsp;przypadku odstępstw od ustalonej normy. Funkcjonalnością, która odpowiada tej potrzebie 
      jest <strong>dowolnie konfigurowalny pulpit pracownika z możliwością umieszczenia na nim 
      wybranych widgetów</strong>. Opcją niezwykle przydatną w&nbsp;omawianym przypadku są widgety 
      generowane na podstawie raportów pracy. Przejrzyste tabele i atrakcyjne wizualnie wykresy 
      pozwalają trzymać rękę na pulsie i&nbsp;dostarczają aktualną wiedzę o stanie Twojego przedsiębiorstwa.
      </p>

      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie2]}
        alts={["Raporty pracy jako widgety"]}
      />
      </Container>

      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Gotowe raporty pracy w&nbsp;programie do serwisu – co możesz sprawdzić?</strong>
      </h2>
      <h3 style={{textAlign: "left"}}><strong>Sytuacja finansowa przedsiębiorstwa</strong></h3>
      <p>
      Sprawnie prosperująca firma to taka, która ze swej działalności osiąga zyski. Są one celem, 
      do którego dąży niemal każda działająca na rynku organizacja. Aby realizacja założeń finansowych 
      była możliwa, konieczne jest odpowiednie planowanie działań, a następnie monitorowanie ich 
      przebiegu. Tylko takie podejście umożliwia analizę bieżącej sytuacji finansowej i śledzenie, 
      czy wszystko przebiega zgodnie z planem.
      </p>
      <p>
      Program do serwisu udostępnia dwa raporty pracy z zakresu finansów przedsiębiorstwa. Pierwszym 
      z nich jest <strong>Koszt zleceń</strong> – zestawienie, które z jednej strony 
      przedstawia <strong>wartość pieniężną, która została poniesiona na każde pojedyncze zlecenie 
      pod względem materiałów oraz robocizny</strong>, a z drugiej jest podsumowaniem wszystkich 
      zrealizowanych prac. Pozwala określić, którzy kontrahenci (powiązani ze zleceniem) generują 
      najwyższe koszty i&nbsp;ocenić, czy współpraca z nimi jest opłacalna.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie3]}
        alts={["Raport pracy o koszcie zleceń"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Drugim istotnym zestawieniem jest raport <strong>Rentowność zleceń</strong>, który oprócz 
      kosztowności poszczególnych zleceń pozwala na <strong>analizę dwóch kluczowych wymiarów – zysku 
      i rentowności</strong>. Dzięki temu możliwe jest poddanie głębszej weryfikacji relacji 
      zawieranych z konkretnymi kontrahentami. Jako osoba zajmująca się raportowaniem z łatwością 
      ocenisz po wskaźniku rentowności, czy dalsza realizacja zleceń dla Twojego klienta po 
      określonych  warunkach ma sens i w jakim kierunku warto podjąć działania.
      </p>
     <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie4]}
        alts={["Raport pracy o rentowności zleceń"]}
      />
      </Container>
      <Container className="container container--text">
      <h3 style={{textAlign: "left"}}><strong>Relacje z&nbsp;kontrahentami</strong></h3>
      <p>
      Celem każdego przedsiębiorstwa jest budowanie trwałych i silnych relacji 
      z&nbsp;kontrahentami – odbiorcami produktów lub usług. Klienci jako podstawa prowadzenia 
      działalności serwisowej wykazują chęć kontynuowania współpracy wtedy, gdy są zadowoleni 
      z realizowanych prac i nie mają zastrzeżeń co do ich poziomu. Aby analizować rozkład 
      zadowolenia kontrahentów warto wykorzystać gotowy raport pracy z&nbsp;programu 
      serwisowego – zestawienie <strong>Liczba zleceń według typów i&nbsp;kontrahentów w okresie</strong> będące 
      podsumowaniem <strong>liczby oraz rodzaju zleceń zrealizowanych dla określonych kontrahentów 
      przez Twoją firmę.</strong>
      </p>
      <p>
      Co będzie świadczyć o tym, że klienci są zadowoleni z solidnej pracy Twoich serwisantów?
      </p>
      <p>
      → Wskaźnikiem takim będzie duża liczba zleceń związanych z cyklicznymi przeglądami 
      sprzętu. Wysoki wynik w tym obszarze powinien Cię z pewnością cieszyć.
      </p>
      <p>
      A co może sugerować, że poziom pracy nie jest zadowalający?
      </p>
      <p>→ Takim aspektem będzie nadmiarowa liczba zleceń z zakresu reklamacji. To znak, że 
      pierwotnie realizowane czynności nie zostały zrealizowane z odpowiednią dbałością o jakość 
      i relacja z danym kontrahentem może wkrótce nie być kontynuowana.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie5]}
        alts={["Raport pracy o liczbie zleceń"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Zadowolenie ze współpracy po stronie klienta to jedno. Ważne jest również to, aby Twoja 
      firma odnosiła korzyści z&nbsp;relacji z&nbsp;danym kontrahentem. Ocenie efektywności tego obszaru 
      służyły zestawienia finansowe przedstawione wyżej, ale ich uzupełnieniem jest kolejny 
      raport pracy – <strong>Pracochłonność zleceń według typów i&nbsp;kontrahentów w&nbsp;okresie</strong>, który 
      określa, <strong>ile godzin poświęcili Twoi pracownicy na realizację zleceń dla określonych 
      klientów</strong>. Jeśli tendencja jest taka, że korzyści nie rekompensują czasu, który 
      został poświęcony, to warto zastanowić się nad dalszym przebiegiem relacji.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie6]}
        alts={["Raport pracy o pracochłonności zleceń"]}
      />
      </Container>
      <Container className="container container--text">
      <h3 style={{textAlign: "left"}}><strong>Efektywność pracowników</strong></h3>

      <p>
      Prowadząc działalność serwisową szczególną uwagę należy zwracać na efektywność pracowników. 
      Jest to niezwykle istotne dla rentownego wykonywania zleceń oraz dążenia do zadowolenia 
      wśród kontrahentów. Aby sprawnie monitorować działania podejmowane przez serwisantów 
      warto korzystać z raportu pracy <strong>Czas poświęcony na zlecenia dla pracownika 
      w&nbsp;okresie</strong>. To zestawienie, którego celem jest <strong>podsumowanie czasu wykorzystanego 
      na realizację określonych zleceń. </strong>
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie7]}
        alts={["Raport pracy o poświęconym czasie"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Ten raport pracy pozwala między innymi na:
      </p>
      <p>
      <ul>
      <li>
      definiowanie norm czasu pracy w&nbsp;odniesieniu do określonego typu zlecenia,</li>
      <li>odpowiednie przydzielanie pracowników do danych zleceń,</li>
      <li>dokładniejsze wycenianie kosztów zleceń.</li>
      </ul>
      </p>
      </Container>

       <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawnie analizować raporty pracy serwisowej?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;raportować działania w&nbsp;programie do serwisu
            </Link>
          </div>
          </div>
    </section>

    <Container className="container container--text">
      <h3 style={{textAlign: "left"}}><strong>Wadliwość sprzętu</strong></h3>

      <p>
      Raport pracy <strong>Liczba zleceń według typów i&nbsp;urządzeń w okresie</strong> to kolejne 
      znaczące zestawienie. Dane w&nbsp;nim zgromadzone przedstawiają <strong>liczbę zleceń według typów 
      oraz urządzeń zrealizowanych w danym okresie</strong>, co pozwala na bieżącą analizę liczby 
      zleceń wykonanych w odniesieniu do określonego sprzętu. Sprawia to, że jako osoba zarządzająca 
      pracami serwisowymi masz możliwość szybkiego reagowania w&nbsp;momencie, w którym liczba zleceń 
      typu reklamacja przewyższa ustaloną normę. 
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie8]}
        alts={["Raport pracy o liczbie zleceń"]}
      />
      </Container>
      <Container className="container container--text">
      <p>
      Dodatkowym zestawieniem z zakresu zarządzania bazą urządzeń jest raport <strong>Gospodarka 
      agazynowa – ruchy magazynowe</strong>, który pozwala <strong>śledzić procesy zachodzące 
      w Twoich magazynach</strong>. Z łatwością możesz zobaczyć, ile produktów pojawiło się 
      w&nbsp;magazynie, ile zostało z niego zabranych, a&nbsp;także to, jakie ruchy wewnętrzne mają miejsce.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie9]}
        alts={["Raport pracy o ruchach magazynowych"]}
      />
      </Container>

      <Container className="container container--text">
      <h3 style={{textAlign: "left"}}><strong>Wyniki sprzedażowe</strong></h3>

      <p>
      Działalność sprzedażowa realizowana przez część przedsiębiorstw serwisowych także uzyskała 
      swoje własne zestawienia w&nbsp;programie do zarządzania zleceniami. Następujące raporty 
      pracy – <strong>Ofertowanie według pozycji, Zamówienia według pozycji i Sprzedaż netto 
      według pozycji</strong> tworzone są na podstawie odpowiadających im dokumentów systemowych.
      </p>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        }}
        images={[data.zdjecie10]}
        alts={["Raport pracy o sprzedaży"]}
      />
      </Container>
      <Container className="container container--text">

      <p>Analizując trzy powyższe zestawienia możesz z łatwością określić np.:
      </p>
      <p>
      <ul>
      <li>wartość ofert, zamówień i sprzedaży w&nbsp;odpowiednim okresie,</li>
      <li>kontrahentów generujących najwyższą sprzedaż,</li>
      <li>handlowców składających oferty o&nbsp;najwyższej wartości,</li>
      <li>czy też produkty, które są najczęściej zamawiane.</li>
      </ul>
      </p>
      </Container>
  
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Podsumowanie</strong>
      </h2>
      <p>
     Raportowanie i analiza to działania, które nie są tak trudne jak mogłoby się wydawać na 
     pierwszy rzut oka. Wszystko to dzięki kompleksowemu programowi serwisowemu z wbudowaną 
     funkcjonalnością automatycznych raportów pracy. Już teraz przenieś swój proces raportowania 
     do odpowiedniego narzędzia, trzymaj rękę na pulsie i ciesz się oszczędnością czasu.
     </p>
     <p>
     Przedstawione gotowe raporty systemowe nie spełniają Twoich potrzeb? Nie martw się. W swojej 
     ofercie posiadamy także możliwość tworzenia indywidualnych zestawień na zamówienie. Chcesz 
     dowiedzieć się więcej o&nbsp;tej opcji? <Link to="/kontakt/"><strong>Skontaktuj się z naszymi specjalistami</strong>.</Link>
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz sprawnie analizować raporty pracy serwisowej?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;raportować działania w&nbsp;programie do serwisu
            </Link>
          </div>
          </div>
    </section> 
      <p>
        <strong>Polecamy również:</strong>
        </p>
        <p>
        <ul>
          <li>
            <Link to="/analiza-przedwdrozeniowa/">Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?</Link>
          </li>
          <li>
            <Link to="/system-do-obslugi-zg%C5%82oszen-serwisowych/">
              {" "}
              Jak wygląda system do obsługi zgłoszeń serwisowych?
            </Link>
          </li>
          <li>
            <Link to="/integracja-systemow-erp/">
              {" "}
             Integracja systemów ERP z programem do obsługi zleceń
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default raportpracy;
